import React, { Suspense, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Switch, Route } from 'react-router-dom';
import AuthContext from '../context/Auth/AuthContext';
import { getAdminInfo } from '../api/auth';
import useWindowSize from '../hooks/windowSize/useWindowSize';
import {
  SET_USER_NAME,
  SET_USER_LEVEL,
  // SET_USER_PORTRAIT_URL,
} from '../context/Auth/types';
import ConditionalWrapper from '../containers/ConditionalWrapper';
import PageContent from '../containers/PageContent';
import Breadcrumb from '../pages/Breadcrumb';
import LoadingPageContent from '../components/LoadingPageContent';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import config from './config';
import PrivateRoute from './PrivateRoute';

const RouteGuard = () => {
  const { height: innerHeight } = useWindowSize();
  const { user, dispatch: dispatchUser } = useContext(AuthContext);
  const [initCompleted, setInitCompleted] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (_.isEmpty(user.level)) {
      const token = localStorage.getItem('smrs_t');

      const getAdmin = async () => {
        const [adminInfoResp, adminInfoError] = await getAdminInfo();

        if (adminInfoError) {
          localStorage.removeItem('smrs_t');
        }

        if (adminInfoResp) {
          dispatchUser({
            type: SET_USER_NAME,
            payload: adminInfoResp.email,
          });
          dispatchUser({
            type: SET_USER_LEVEL,
            payload: adminInfoResp.adminType,
          });
          // dispatchUser({
          //   type: SET_USER_PORTRAIT_URL,
          //   payload: 'https://picsum.photos/50',
          // });
        }

        setInitCompleted(true);
      };
      if (isMounted && token) getAdmin();
      if (isMounted && !token) setInitCompleted(true);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  if (!initCompleted) return null;

  return (
    <div className="flex" style={{ maxHeight: `${innerHeight}px` }}>
      <Switch>
        {_.map(config, (route) => {
          return (
            <Route key={route.name} path={route.path}>
              {route.hasSidebar && <Sidebar />}
              <div className="flex-1">
                <ConditionalWrapper
                  condition={route.needAuth}
                  wrapper={(children) => (
                    <PrivateRoute>{children}</PrivateRoute>
                  )}
                >
                  {route.hasNavbar && <Navbar />}
                  <Suspense fallback={<LoadingPageContent />}>
                    <PageContent
                      hasNavbar={route.hasNavbar}
                      hasSidebar={route.hasSidebar}
                    >
                      {!!_.get(route, 'breadcrumb.show') && <Breadcrumb />}
                      <route.component />
                    </PageContent>
                  </Suspense>
                </ConditionalWrapper>
              </div>
            </Route>
          );
        })}
      </Switch>
    </div>
  );
};

export default RouteGuard;
