import React from 'react';
import './index.scss';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Loading({ size = '2.5rem', color = '#6d757d', className = '' }) {
  const loadingCircleStyle = {
    width: size,
    height: size,
  };
  const dotStyle = {
    backgroundColor: color,
  };
  const loadingClass = classNames('loading-circle', className);

  return (
    <div className={loadingClass} style={loadingCircleStyle}>
      {_.map(Array(12), (item, index) => {
        return (
          <div
            key={index}
            className={`loading-circle${index + 1} loading-child`}
          >
            <div className="loading-dot" style={dotStyle}></div>
          </div>
        );
      })}
    </div>
  );
}

Loading.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Loading;
