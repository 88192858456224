import { lazy } from 'react';
import * as paths from './routePaths';
import * as names from './routeNames';

const Login = lazy(() => import('../pages/Login'));
const ProjectOverview = lazy(() => import('../pages/ProjectOverview'));
const DataReport = lazy(() => import('../pages/DataReport'));
const UserManage = lazy(() => import('../pages/UserManage'));
const TaskGroups = lazy(() => import('../pages/TaskGroups'));
const TaskUserRecords = lazy(() => import('../pages/TaskUserRecords'));
const TaskGroupUserRecords = lazy(() =>
  import('../pages/TaskGroupUserRecords'),
);
const TaskRecords = lazy(() => import('../pages/TaskRecords'));
const TaskGroupRecords = lazy(() => import('../pages/TaskGroupRecords'));
const Awards = lazy(() => import('../pages/Awards'));
const AwardUserRecords = lazy(() => import('../pages/AwardUserRecords'));
const AwardRecords = lazy(() => import('../pages/AwardRecords'));
const ProjectCustomSetting = lazy(() =>
  import('../pages/ProjectCustomSetting'),
);
const ProjectLevelSetting = lazy(() => import('../pages/ProjectLevelSetting'));
const ProjectApiSetting = lazy(() => import('../pages/ProjectApiSetting'));
const ProjectTagManagement = lazy(() => import('../pages/TagManagement'));
const DefaultPage = lazy(() => import('../pages/DefaultPage'));

const config = [
  {
    name: names.LOGIN,
    path: paths.LOGIN,
    component: Login,
    hasNavbar: false,
    hasSidebar: false,
    breadcrumb: {
      show: false,
    },
  },
  {
    name: names.PROJECT_OVERVIEW,
    path: paths.OVERVIEW,
    component: ProjectOverview,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: false,
    breadcrumb: {
      show: false,
    },
  },
  {
    name: names.DATA_REPORT,
    path: paths.DATA_REPORT,
    component: DataReport,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: false,
      parent: null,
    },
  },
  {
    name: names.USER_MANAGE,
    path: paths.USER_MANAGE,
    component: UserManage,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.TASK_USER_RECORDS,
    path: paths.TASK_USER_RECORDS,
    component: TaskUserRecords,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: names.TASK_GROUPS,
    },
  },
  {
    name: names.TASK_GROUP_USER_RECORDS,
    path: paths.TASK_GROUP_USER_RECORDS,
    component: TaskGroupUserRecords,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: names.TASK_GROUPS,
    },
  },
  {
    name: names.TASK_RECORDS,
    path: paths.TASK_RECORDS,
    component: TaskRecords,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: names.TASK_GROUPS,
    },
  },
  {
    name: names.TASK_GROUP_RECORDS,
    path: paths.TASK_GROUP_RECORDS,
    component: TaskGroupRecords,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: names.TASK_GROUPS,
    },
  },
  {
    name: names.TASK_GROUPS,
    path: paths.TASK_GROUPS,
    component: TaskGroups,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.AWARDS_USERS_RECORDS,
    path: paths.AWARDS_USERS_RECORDS,
    component: AwardUserRecords,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: names.AWARDS,
    },
  },
  {
    name: names.AWARDS_RECORDS,
    path: paths.AWARDS_RECORDS,
    component: AwardRecords,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: names.AWARDS,
    },
  },
  {
    name: names.AWARDS,
    path: paths.AWARDS,
    component: Awards,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_CUSTOM_SETTING,
    path: paths.CUSTOM_SETTING,
    component: ProjectCustomSetting,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_LEVEL_SETTING,
    path: paths.LEVEL_SETTING,
    component: ProjectLevelSetting,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_API_SETTING,
    path: paths.API_SETTING,
    component: ProjectApiSetting,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_TAG_MANAGEMENT,
    path: paths.TAG_MANAGEMENT,
    component: ProjectTagManagement,
    needAuth: true,
    hasNavbar: true,
    hasSidebar: true,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: 'root',
    path: paths.ROOT,
    component: DefaultPage,
    needAuth: true,
    hasNavbar: false,
    hasSidebar: false,
    breadcrumb: {
      show: false,
      parent: null,
    },
  },
];

export default config;
