import { gql } from '@apollo/client';
import {
  ProjectNameFragment,
  CustomSettingFragment,
  ProjectLevelsFragment,
  ProjectApiFragment,
  ProjectTagsFragment,
} from '../fragments/project';
import { OrganizationFragment } from '../fragments/organization';

export const GetProjectsQuery = gql`
  query GetProjectsQuery {
    projects {
      nodes {
        ...ProjectNameFragment
        organization {
          ...OrganizationFragment
        }
        updatedAt
        description
      }
    }
  }
  ${ProjectNameFragment}
  ${OrganizationFragment}
`;

export const GetProjectNamesQuery = gql`
  query GetProjectNamesQuery {
    projects {
      nodes {
        ...ProjectNameFragment
      }
    }
  }
  ${ProjectNameFragment}
`;

export const GetCustomSettingQuery = gql`
  query GetCustomSettingQuery($projectId: ID!) {
    project(id: $projectId) {
      ...CustomSettingFragment
    }
  }
  ${CustomSettingFragment}
`;

export const GetProjectLevelsQuery = gql`
  query GetProjectLevelsQuery($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectLevelsFragment
    }
  }
  ${ProjectLevelsFragment}
`;

export const GetProjectApiQuery = gql`
  query GetProjectApiQuery($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectApiFragment
    }
  }
  ${ProjectApiFragment}
`;

export const GetProjectTagsQuery = gql`
  query GetProjectTagsQuery($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectTagsFragment
    }
  }
  ${ProjectTagsFragment}
`;
