import React, { useContext } from 'react';
import './index.css';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LOGIN } from '../../routes/routePaths';
import AuthContext from '../../context/Auth/AuthContext';
import { logout } from '../../api/auth';
import { prefix, suffix } from '../../utils/breadcrumb';
import findCurrentRoute from '../../utils/route/findCurrentRoute';
import defaultPortrait from '../../assets/images/banner/defaultPortrait.svg';

function Navbar() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const matchRoute = findCurrentRoute(location);

  const navbarDropdown = [
    {
      text: t('login.logout'),
      callback: async () => {
        const [loginResp] = await logout();
        if (loginResp) {
          localStorage.removeItem('smrs_t');
          history.push(LOGIN);
        }
      },
    },
  ];

  return (
    <div className="navbar">
      <div className="flex-1 px-6 text-18 leading-6 font-semibold text-dark-grey">
        {`${prefix(matchRoute.name)} `}
        {t(`breadcrumb.${matchRoute.name}`)}
        {` ${suffix(matchRoute.name)}`}
      </div>
      <div className="dropdown mx-6">
        <button className="btn-navbar-user btn-dropdown">
          <img
            className="h-8 w-8 object-cover rounded-full"
            src={
              !_.isEmpty(user.portraitUrl) ? user.portraitUrl : defaultPortrait
            }
            alt="Portrait"
          />
          <div className="flex flex-col items-start ml-3 text-dark-grey-300">
            <div className="flex items-center text-14 leading-4 font-semibold">
              {_.get(user, 'name', '')}
              <i className="gsif gsif-triangle text-blue ml-1 transform rotate-180 scale-50"></i>
            </div>
            <div className="text-12 leading-3 mt-1">
              {_.has(user, 'level') && t(`adminType.${user.level}`)}
            </div>
          </div>
        </button>
        <div className="list-dropdown">
          {_.map(navbarDropdown, (item, index) => {
            return (
              <button
                key={index}
                className="btn-dropdown-item"
                onMouseDown={item.callback}
              >
                {item.text}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
