import { gql } from '@apollo/client';

export const ProjectNameFragment = gql`
  fragment ProjectNameFragment on Project {
    id
    name
  }
`;

export const CustomSettingFragment = gql`
  fragment CustomSettingFragment on Project {
    id
    name
    logoImageUrl
    coverImageUrl
    memberCardImageUrl
    webTitle
    themeColor
    preferences {
      fillBirthday
      fillEmail
      fillName
      fillTel
      needOtp
    }
    policy
    contributionButtonLable
    contributionButtonUrl
    awardButtonLable
    awardButtonUrl
    webLang
    chatfuelSignupUrl
    chatfuelSignupSuccessUrl
    chatfuelApiToken
  }
`;

export const ProjectLevelsFragment = gql`
  fragment ProjectLevelsFragment on Project {
    id
    levels {
      title
      score
    }
  }
`;

export const ProjectApiFragment = gql`
  fragment ProjectApiFragment on Project {
    id
    credentials {
      id
      token
      createdAt
    }
    memberLoginUrl
  }
`;

export const ProjectTagsFragment = gql`
  fragment ProjectTagsFragment on Project {
    id
    storeTags {
      id
      name
    }
    userTags {
      id
      name
    }
    productTags {
      id
      name
    }
  }
`;
