import _ from 'lodash';

const variables = {
  endPoint: process.env.REACT_APP_END_POINT,
};

export const getBaseUrl = () => {
  return _.get(variables, `endPoint`, '');
};

export const getGqlEndPoint = () => {
  return `${_.get(variables, `endPoint`, '')}/api/console/v3/graphql`;
};

export const getUploadPath = () => {
  return `${_.get(variables, `endPoint`, '')}/api/console/v3/direct_uploads`;
};
