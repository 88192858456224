import React, { useContext } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import AuthContext from '../context/Auth/AuthContext';
import { LOGIN } from './routePaths';

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  if (_.isEmpty(user.level)) {
    return <Redirect to={LOGIN} />;
  }
  return children;
};

export default PrivateRoute;
