import React, { useMemo } from 'react';
import './index.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { generatePath, matchPath } from 'react-router';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetProjectNamesQuery } from '../../services/graphql/queries/project';
import useWindowSize from '../../hooks/windowSize/useWindowSize';
import logoGoSkyLoyal from '../../assets/images/logo/GoSky-Loyal.svg';
import { CUSTOM_SETTING, OVERVIEW } from '../../routes/routePaths';
import getSidebarLinks from './getSidebarLinks';

const Sidebar = () => {
  const { height: innerHeight } = useWindowSize();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { data } = useQuery(GetProjectNamesQuery);
  const projects = useMemo(() => _.get(data, 'projects.nodes', []), [data]);

  const sidebarLinks = getSidebarLinks({ t, location, params });

  const renderList = (list) => {
    return _.map(list, (listItem) => {
      if (!listItem.label) return false;
      const linkClass = classNames('sidebar-item-link', {
        active: listItem.active,
        'parent-node': !!listItem.links,
      });
      return (
        <div key={listItem.label} className="sidebar-item">
          {!listItem.active && listItem.link ? (
            <Link className="sidebar-item-link" to={listItem.link}>
              {listItem.icon && <i className={`gsif ${listItem.icon}`}></i>}
              {listItem.label}
            </Link>
          ) : (
            <span className={linkClass} to={listItem.link}>
              {listItem.icon && <i className={`gsif ${listItem.icon}`}></i>}
              {listItem.label}
            </span>
          )}

          {listItem.links && renderList(listItem.links)}
        </div>
      );
    });
  };

  const onProjectChange = (e) => {
    const { value } = e.target;
    history.push(generatePath(CUSTOM_SETTING, { projectId: value }));
    const isCurrentCustomSettingPage = !!matchPath(
      location.pathname,
      CUSTOM_SETTING,
    );
    if (isCurrentCustomSettingPage) history.go(0);
  };

  return (
    <div className="sidebar">
      <Link to={OVERVIEW}>
        <img className="logo-sidebar" src={logoGoSkyLoyal} alt="GoSky Loyal" />
      </Link>
      <div className="select-sidebar-projects select">
        <select onChange={onProjectChange} value={params.projectId}>
          {_.map(projects, (project) => {
            return (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            );
          })}
        </select>
      </div>
      <div
        className="scrollbar pr-4 pb-7"
        style={{ maxHeight: `calc(${innerHeight}px - 11.6875rem)` }}
      >
        {renderList(sidebarLinks)}
      </div>
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  className: PropTypes.string,
  projectList: PropTypes.array,
  sidebarItems: PropTypes.array,
};
