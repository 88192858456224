import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import RouteGuard from './routes/RouteGuard';
import RouteProvider from './context/Route/RouteProvider';

function App() {
  return (
    <RouteProvider>
      <Router>
        <RouteGuard />
      </Router>
    </RouteProvider>
  );
}

export default App;
