import React from 'react';
import useWindowSize from '../../hooks/windowSize/useWindowSize';

const PageContent = ({ hasNavbar = false, hasSidebar = false, children }) => {
  const { height: innerHeight, width: innerWidth } = useWindowSize();
  let height = `${innerHeight}px`;
  if (hasNavbar) height += ' - 4.375rem';
  let width = `${innerWidth}px`;
  if (hasSidebar) width += ' - 16.25rem';

  return (
    <div
      className="page-content scrollbar"
      style={{ height: `calc(${height})`, width: `calc(${width})` }}
    >
      {children}
    </div>
  );
};

export default PageContent;
