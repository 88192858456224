import _ from 'lodash';
import { generatePath } from 'react-router';
import * as paths from '../../routes/routePaths';

const defaultParams = {
  id: ' ',
  typeId: ' ',
  taskId: ' ',
  taskGroupId: ' ',
  awardId: ' ',
  a: ' ',
  b: ' ',
};

const linksOrigin = [
  {
    sidebarLabel: 'dataReport',
    sidebarIcon: 'gsif-dashboard',
    link: paths.DATA_REPORT,
  },
  {
    sidebarLabel: 'userManage',
    sidebarIcon: 'gsif-people',
    link: paths.USER_MANAGE,
  },
  {
    sidebarLabel: 'taskGroups',
    sidebarIcon: 'gsif-star-circle',
    link: paths.TASK_GROUPS,
    links: [
      {
        link: paths.TASK_USER_RECORDS,
      },
      {
        link: paths.TASK_GROUP_USER_RECORDS,
      },
      {
        link: paths.TASK_RECORDS,
      },
      {
        link: paths.TASK_GROUP_RECORDS,
      },
    ],
  },
  {
    sidebarLabel: 'awards',
    sidebarIcon: 'gsif-gift-card',
    link: paths.AWARDS,
    links: [
      {
        link: paths.AWARDS_USERS_RECORDS,
      },
      {
        link: paths.AWARDS_RECORDS,
      },
    ],
  },
  {
    sidebarLabel: 'smrsSetting',
    sidebarIcon: 'gsif-cog',
    links: [
      {
        sidebarLabel: 'customSetting',
        link: paths.CUSTOM_SETTING,
      },
      {
        sidebarLabel: 'levelSetting',
        link: paths.LEVEL_SETTING,
      },
      {
        sidebarLabel: 'apiSetting',
        link: paths.API_SETTING,
      },
      {
        sidebarLabel: 'tagManagement',
        link: paths.TAG_MANAGEMENT,
      },
    ],
  },
];

const getSidebarLinks = ({ t, location, params }) => {
  const parameters = _.merge(defaultParams, params);

  const linksRecursion = (links) => {
    let hasActive = false;
    const result = _.map(links, (linkItem) => {
      const nestedLinks = _.has(linkItem, 'links')
        ? linksRecursion(linkItem.links)
        : false;
      const currentLink = generatePath(linkItem.link, parameters);
      const currentActive = location.pathname === currentLink;
      if (currentActive || nestedLinks.hasActive) hasActive = true;
      return {
        ...(linkItem.sidebarLabel && {
          label: t(`sidebar.${linkItem.sidebarLabel}`),
        }),
        ...(linkItem.sidebarIcon && { icon: linkItem.sidebarIcon }),
        ...(linkItem.link && { link: currentLink }),
        ...(nestedLinks && { links: nestedLinks.result }),
        active: (nestedLinks && nestedLinks.hasActive) || currentActive,
      };
    });
    return { result, hasActive };
  };

  const { result } = linksRecursion(linksOrigin);
  return result;
};

export default getSidebarLinks;
