import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer, Slide } from 'react-toastify';
import gqlClient from './services/graphql';
import AuthProvider from './context/Auth/AuthProvider';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './assets/styles/common/_main.scss';
import './api';
import './i18n/i18n';

if (process.env.NODE_ENV === 'production') {
  if (window.location.protocol !== 'https:') {
    window.location.replace(
      `https:${window.location.href.substring(
        window.location.protocol.length,
      )}`,
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={gqlClient}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
    <ToastContainer
      autoClose={3000}
      closeButton={false}
      transition={Slide}
      hideProgressBar={true}
    />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
