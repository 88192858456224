export const ROOT = 'root';
export const LOGIN = 'login';
export const PROJECT_OVERVIEW = 'projectOverview';
export const DATA_REPORT = 'dataReport';
export const USER_MANAGE = 'userManage';
export const TASK_GROUPS = 'taskGroups';
export const TASK_USER_RECORDS = 'taskUserRecords';
export const TASK_GROUP_USER_RECORDS = 'taskGroupUserRecords';
export const TASK_RECORDS = 'taskRecords';
export const TASK_GROUP_RECORDS = 'taskGroupRecords';
export const AWARDS = 'awards';
export const AWARDS_USERS_RECORDS = 'awardsUsersRecords';
export const AWARDS_RECORDS = 'awardsRecords';
export const PROJECT_CUSTOM_SETTING = 'projectCustomSetting';
export const PROJECT_LEVEL_SETTING = 'projectLevelSetting';
export const PROJECT_API_SETTING = 'projectApiSetting';
export const PROJECT_TAG_MANAGEMENT = 'projectTagManagement';
